import {checkRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';
import {fetchProtectedData} from '../../api/fetch';
import {Await, defer, useLoaderData} from 'react-router-dom';
import React from 'react';
import {Spinner} from '../../components/Spinner/Spinner';
import {Text} from '../../components/Text/Text';
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from '../../components/Table/Table';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

export async function sentNotificationsLoader({request, params}) {
    await checkRequiredAccount(Permissions.NOTIFICATION_VIEW);

    const notificationPromise = fetchProtectedData(request,`patient/${params.patientUUID}/notification?isSent=true`);

    return defer({notificationPromise});
}

const SentNotifications = () => {
    const {notificationPromise} = useLoaderData();

    return (
        <React.Suspense fallback={
            <SpinnerWrapper>
                <Spinner />
            </SpinnerWrapper>
        }>
            <Await resolve={notificationPromise} errorElement={<Text>De berichten kunnen niet worden opgehaald. Probeer het opnieuw.</Text>}>
                {(notificationData) => {
                    if(notificationData.length === 0) return <Text>Er zijn nog geen berichten toegevoegd.</Text>

                    return (
                        <>
                            <Table>
                                <TableHead>
                                    <TableHeadRow>
                                        <TableHeadCell $flex="0" $minWidth="196px">Datum</TableHeadCell>
                                        <TableHeadCell $flex="0" $minWidth="128px">Type</TableHeadCell>
                                        <TableHeadCell>Titel</TableHeadCell>
                                        <TableHeadCell>Bericht</TableHeadCell>
                                    </TableHeadRow>
                                </TableHead>

                                {notificationData.map((item, index) => {
                                    const isDeleted = Boolean(item.removedManually);

                                    return (
                                        <TableRow key={item.id} $isClickable={false}>
                                            <TableRowInner $isOddRow={index % 2 === 0} $deleted={isDeleted}>
                                                <TableRowCell $flex="0" $minWidth="196px" $deleted={isDeleted}>{item.sendAt}</TableRowCell>
                                                <TableRowCell $flex="0" $minWidth="128px" $deleted={isDeleted}>{item.type}</TableRowCell>
                                                <TableRowCell $variant="title" $deleted={isDeleted}>
                                                    <Text $truncate $clamp={1} $noMargin>{item.title}</Text>
                                                </TableRowCell>
                                                <TableRowCell $deleted={isDeleted}>
                                                    <Text $truncate $clamp={1} $noMargin>{item.text}</Text>
                                                </TableRowCell>
                                            </TableRowInner>
                                        </TableRow>
                                    );
                                })}
                            </Table>
                        </>
                    );
                }}
            </Await>
        </React.Suspense>
    );
}

export default SentNotifications;